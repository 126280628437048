<template>
  <b-card v-if="musicData">
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="4"
        class="d-flex justify-content-between"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start align-items-center">
          <b-avatar
            :src="musicData ? musicData.thumbnail : 'https://via.placeholder.com'"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ musicData.name_ar }} ({{ musicData.name }})
              </h4>

              <span class="card-text">{{ musicData.description }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-musics-edit', params: { id: musicData.id } }"
                variant="danger"
              >
                Edit
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="deleteMusic(musicData.id)"
              >
                Delete
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col>
        <div class="d-flex justify-content-end align-items-center h-100">
          <audio controls>
            <source
              :src="musicData.music"
              type="audio/ogg"
            >
            <source
              :src="musicData.music"
              type="audio/mpeg"
            >
            Your browser does not support the audio element.
          </audio>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
    BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import router from '@/router'

// repository
import MusicRepository from '@/abstraction/repository/musicRepository'

const musicRepository = new MusicRepository()

export default {
    components: {
        BCard,
        BButton,
        BRow,
        BCol,
        BAvatar,
    },
    props: {
        musicData: {
            // type: Object,
            required: true,
        },
    },
    methods: {
        async deleteMusic(musicId) {
            await musicRepository.delete(musicId)
            router.push({ name: 'apps-musics-list' })
        },
    },
}
</script>

<style></style>
