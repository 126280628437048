<template>
  <div v-if="form">
    <template>
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <music-view-music-info-card :music-data="form" />
        </b-col>
      </b-row>
      <b-tabs
        v-if="form"
        pills
      >
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <span>
              <i class="WMi-music" />
            </span>
            <span class="d-none d-sm-inline">Music</span>
          </template>
          <b-row>
            <b-col cols="12">
              <music-view-tab-data :music-data="form" />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <span>
              <i class="WMi-user" />
            </span>
            <span class="d-none d-sm-inline text-capitalize">lyric</span>
          </template>
          <b-row>
            <b-col cols="12">
              <b-card>
                <b-card-body class="text-capitalize">
                  <b-card-title>lyric</b-card-title>
                  <b-card-sub-title>{{ form.lyric }}</b-card-sub-title>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </template>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BTabs,
    BTab,
    BCardSubTitle,
    BCardTitle,
    BCardBody,
} from 'bootstrap-vue'
import router from '@/router'
import MusicRepository from '@/abstraction/repository/musicRepository'
import MusicViewMusicInfoCard from './MusicViewMusicInfoCard.vue'
import MusicViewTabData from './MusicViewTabData.vue'

// repository

const musicRepository = new MusicRepository()

export default {
    components: {
        BRow,
        BCol,
        BAlert,
        BLink,
        BCard,
        BTabs,
        BTab,
        BCardSubTitle,
        BCardTitle,
        BCardBody,
        // Local Components
        MusicViewMusicInfoCard,
        MusicViewTabData,
    },
    setup() {
        const form = ref(null)
        const musicId = ref(router.currentRoute.params.id)
        onMounted(async () => {
            if (musicId.value) {
                const resource = await musicRepository.show(musicId.value)
                form.value = resource
            }
        })
        return {
            form,
        }
    },
}
</script>

<style></style>
